/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import "./src/index.scss";

import { StoreProvider, createStore } from "easy-peasy";
import { WrapRootElementBrowserArgs } from "gatsby";
import React from "react";
import { InitStore, Schema as SchemaType, schemaModel } from "~/utils/store";

/** Create store, store data is populated on InitStore mount. */

const store = createStore<SchemaType>(schemaModel);

/** Wrap root element. */
export const wrapRootElement: React.FC<WrapRootElementBrowserArgs> = ({
	element,
}) => {
	return (
		<StoreProvider store={store}>
			<InitStore />
			{element}
		</StoreProvider>
	);
};
